export const sessionKey = {
	detailId: 'note-detail-id',
	listSearch: 'note-list-search',
};

export const noteModeKey = 'note-mode';

export enum modesType {
	create = 'create',
	preview = 'preview',
	editAndPreview = 'editAndPreview',
}

export const initEntity = {
	title: '',
	type: 0,
	content: '',
	version: 0,
	_id: '',

	createTime: '',
	userId: '',
	tagIds: [],
};

export const notePageUrl = '/pc/note';

export const mobileNoteUrl = '/mobile/note';

export const tabs = [
	{
		title: '创建',
		action: modesType.create,
	},
	{
		title: '列表',
		action: modesType.preview,
	},
	{
		title: '详情',
		action: modesType.editAndPreview,
	},
] as const;

export const KEYS_CONST_NAME = 'key';
