import { modesType } from "../../../modules/note/pc/consts";
import { TrpcBase } from "../../../utils/trpc";
import { useMount } from "ahooks";
import { isNil } from "lodash-es";
import { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useNoteCursor } from "./hooks/useNoteCursor";
import { useUserAgent } from "@/utils/userAgent";

export const NoteContext = createContext<
	ReturnType<typeof useCreateNoteContext>
>({} as ReturnType<typeof useCreateNoteContext>);

export const noteListFetchDefaultParams = {
	defaultValues: {
		key: "",
		tagId: [],
		page: 0,
	},
};

export const noteIdQueryConstantName = "uuid";
export const searchKeyWordQueryConstantName = "q";

export function useCreateNoteContext() {
	const { isMobile, isPC } = useUserAgent();
	const [searchParams, setSearchParams] = useSearchParams();
	const q = searchParams.get(searchKeyWordQueryConstantName);

	/** 列表请求 */
	const uuid = searchParams.get(noteIdQueryConstantName) as string;

	const action =
		(searchParams.get("action") as modesType[number]) ??
		(isPC || !isNil(uuid) ? modesType.preview : modesType.create);

	if (!isNil(q)) {
		noteListFetchDefaultParams.defaultValues.key = q;
	}
	/** 每次用来搜索用的表单 */
	const formHook = useForm(noteListFetchDefaultParams);

	const watchAllFields = formHook.getValues();

	const listHook = TrpcBase.note.list.useQuery(
		{
			key: watchAllFields.key ? watchAllFields.key : "",
			page: watchAllFields.page,
		},
		{},
	);

	const noteDetail = TrpcBase.note.detail.useQuery(uuid ?? "", {
		initialData: {},
		enabled: uuid !== "" && uuid !== "null" && uuid !== null,
	});

	/** 笔记表单 */
	const writeForm = useForm();
	const noteCursor = useNoteCursor();
	const [modalVisible, setModalVisible] = useState(false);

	/** 不依赖渲染时，值的更新，因为form不知道值改变成不同的时机。在应该更新的地方进行触发。
	 * 这里只有两个地方:
	 * 1. 页面进入
	 * 2. 关键 key 更新
	 */
	useMount(() => {
		listHook.refetch();
	});

	return {
		...noteCursor,
		action,
		formHook,
		writeForm,
		modalVisible,
		setModalVisible,
		id: uuid,
		noteDetail,
		listHook,
	};
}

export function useNoteContext() {
	return useContext(NoteContext);
}

export const NoteContextComponent = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const context = useCreateNoteContext();

	return (
		<NoteContext.Provider value={context}>
			{/* <ModalSearchBar></ModalSearchBar> */}

			{children}
		</NoteContext.Provider>
	);
};
